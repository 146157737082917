@import "../../../../scss/style.scss";

.transaction-view-box {
  .status-box {
    span {
      min-width: 20px;
      border: 1px solid #86ddba;
      border-radius: 11px;
      padding: 3px 5px;
      color: #52c41a;
      text-transform: capitalize;
      line-height: 23px;
      display: inline-block;
      &.reject {
        color: tomato;
        border-color: tomato;
      }
      &.pending {
        color: #2f54eb;
        border-color: #2f54eb;
      }
      &.amount {
        margin: 5px 0;
        color: #52c41a;
        border: 1px solid #86ddba;
        background-color: #f0fff9;
      }
    }
  }
}

.paymentData {
  width: 100%;
  border-collapse: collapse;
  tr,
  td,
  th {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 16px;
  }
  thead th {
    text-align: left;
    padding: 5px 16px;
    color: rgba(0, 0, 0, 0.87);
  }
}

.tableWrap{
  margin-bottom: 20px !important;
}

@media (max-width: 576px) {
  .tableWrap {
    overflow: auto;
  }
}
