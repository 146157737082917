@import "../../../../scss/style.scss";

.strategic-profile-section {
  .rating-box {
    .view-leftbox {
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      padding-right: 25px;
      height: 100%;
      .help-img {
        width: 254px;
        height: 137px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info-box {
        margin: 50px 0;
        flex-direction: column;
        .doctor-box {
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          height: 100%;
        }
        .website li p {
          position: relative;
          padding-left: 20px;
          margin-top: 10px;
          a {
            color: rgba(0, 0, 0, 0.65);
            border-bottom: 1px solid rgba(0, 0, 0, 0.65);
            transition: all 0.4s ease-in-out;
            padding-bottom: 2px;
            &:hover {
              color: #1e1e1e;
            }
          }
        }
        .website li p::before {
          content: "";
          width: 15px;
          height: 18px;
          background-repeat: no-repeat;
          background-size: 100%;
          display: block;
          position: absolute;
          left: 0;
          top: 3px;
        }
        .doctor-list {
          li {
            margin-bottom: 10px;
            line-height: 1;
            padding-left: 20px;
            position: relative;
            &::before {
              content: "";
              width: 15px;
              height: 15px;
              background-repeat: no-repeat;
              background-size: 100%;
              display: block;
              position: absolute;
              left: 0;
              top: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        h4 {
          color: #000000;
          font-weight: 500;
          font-size: 20px;
          line-height: 1.5;
          margin-bottom: 10px;
        }
        p {
          color: rgba(0, 0, 0, 0.82);
          font-weight: 500;
          font-size: 15px;
        }
      }
      .add-box {
        .strategic-tag {
          position: relative;
          padding-left: 25px;
          margin-bottom: 10px;
          &::before {
            content: "";
            width: 18px;
            height: 18px;
            background-repeat: no-repeat;
            background-size: 100%;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        p {
          margin-bottom: 10px;
          position: relative;
          padding-left: 25px;
          &:before {
            content: "";
            background-repeat: no-repeat;
            background-size: contain;
            width: 18px;
            height: 18px;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        .add-list {
          li {
            margin-bottom: 15px;
            display: inline-block;
            width: 49%;
            margin-right: 7px;
            padding-left: 25px;
            position: relative;
            vertical-align: top;
            span,
            a {
              color: rgba(0, 0, 0, 0.65);
              display: inline-block;
              font-weight: 400;
              line-height: 1;
            }
            a {
              border-bottom: 1px solid rgba(0, 0, 0, 0.65);
              transition: all 0.4s ease-in-out;
              padding-bottom: 2px;
              &:hover {
                color: #1e1e1e;
              }
            }
            &:before {
              content: "";
              background-repeat: no-repeat;
              background-size: contain;
              width: 18px;
              height: 18px;
              display: block;
              position: absolute;
              left: 0;
              top: 5px;
            }
            &:nth-child(2n) {
              margin-right: 0;
            }
            &:nth-last-child(2),
            &:last-child {
              margin-bottom: 0;
            }
          }
          .phone::before {
            transform: rotate(120deg);
          }
        }
      }
      .view-about-box {
        margin-top: 25px;
        .add-box {
          margin-bottom: 25px;
        }
        h4 {
          font-weight: 500;
          color: #5ba02e;
          margin-bottom: 10px;
          line-height: 1;
        }
        p {
          color: rgba(0, 0, 0, 0.65);
          font-size: 13px;
          font-weight: 500;
          line-height: 1.3;
          margin-bottom: 15px;
        }
        .member-list {
          li {
            border: 1px solid rgba(66, 164, 76, 0.86);
            position: relative;
            display: inline-block;
            width: 100%;
            border-radius: 2px;
            padding: 20px 25px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 20px;
            // &:last-child{
            //     margin-bottom: 0;
            // }
            span {
              background-color: #389e0d;
              border-radius: 2px;
              border: 1px solid #389e0d;
              padding: 3px 5px;
              display: inline-block;
              color: #fff;
              position: absolute;
              right: 15px;
              top: 15px;
            }
            .dots-div {
              position: relative;
              padding-left: 15px;
              text-align: left;
              &:before {
                content: "";
                border: 1px solid #389e0d;
                border-radius: 50%;
                width: 5px;
                height: 5px;
                display: block;
                position: absolute;
                left: 0;
                top: 6px;
              }
            }
          }
        }
        .profile-box {
          text-align: left;
          .community-btn {
            width: 150px;
            display: inline-block;
            margin: 0 auto;
            text-align: center;
            font-weight: 400;
            font-size: 15px;
            margin-right: 20px;
            border-radius: 4px;
            line-height: 40px;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .list-member {
          text-align: center;
        }
      }
    }

    .view-rightbox {
      .react-tabs__tab-list {
        border-color: #5ba02e;
      }
      .react-tabs__tab--selected {
        border-color: #5ba02e;
        background-color: #fff;
      }
    }
    .profile-list-box {
      display: flex;
      align-items: center;
      margin-top: 25px;
      .strategic-person {
        li {
          display: flex;
          width: 100%;
          figure {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 3px solid white;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            &:nth-of-type(2) {
              margin-left: -10px;
            }
            &:nth-of-type(3) {
              margin-left: -10px;
            }
            &:nth-of-type(4) {
              margin-left: -10px;
            }
          }
        }
      }
      span {
        font-weight: 400;
        padding-left: 5px;
        padding-bottom: 5px;
      }
    }
  }

  .image-box {
    padding-top: 20px;
    .react-tabs__tab-list {
      border-bottom: none;
      text-align: center;
      margin-bottom: 20px;
      position: relative;
      i {
        position: absolute;
        right: 0;
        top: 11px;
        cursor: pointer;
        width: 140px;
        height: 30px;
        display: flex;
        align-items: center;
        font-weight: normal;
        font-style: normal;
        color: #389e0d;
        svg {
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }
      }
    }
    .react-tabs__tab--selected {
      bottom: 0;
      border: 2px solid #5ba02e;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      background-color: transparent !important;
      &:before {
        content: "";
        display: none;
      }
    }
    .upload-img {
      width: 100%;
      height: 250px;
      position: relative;
      i {
        position: absolute;
        z-index: 111;
        width: 70px;
        height: 30px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: none;
        @include prefix(transition, all 0.4s ease-in-out);
        justify-content: space-between;
        align-items: center;
        svg {
          width: 20px;
          height: 20px;
          margin: auto;
          cursor: pointer;
          fill: #fff;
          @include prefix(transition, all 0.4s ease-in-out);
        }
        .btn-play {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          .play {
            width: 40px;
            height: 35px;
            margin: auto;
          }
        }
      }
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 11;
        @include prefix(transition, all 0.4s ease-in-out);
        background-repeat: no-repeat;
        background-position: center;
      }
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        @include prefix(transition, all 0.4s ease-in-out);
        background-repeat: no-repeat;
        background-position: center;
      }
      &:hover {
        &:before {
          background-color: rgba(0, 0, 0, 0.7);
          //background-color: rgba(109, 175, 95, 0.5);
        }
        i {
          display: flex;
          //   justify-content: center;
        }
        &:after {
          display: none;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .post-inner {
      .post-list {
        height: calc(100vh + 40px);
        li {
          display: inline-block;
          width: 49%;
          vertical-align: top;
          margin-right: 10px;
          margin-bottom: 10px;
          &:nth-last-child(2) {
            margin-bottom: 0;
          }
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
  }
  .community-box {
    padding: 30px 0 0;
    .postCommentAction {
      p {
        // width: 100%;
        // display: block;
        line-height: 20px;
        margin-bottom: 10px;
        word-break: break-all;
        font-family: 'Jost',sans-serif;

        a{
          color: rgba(0, 0, 0, 0.65);
          border-bottom: 1px solid rgba(0, 0, 0, 0.65);
          @include prefix(transition, all 0.4s ease-in-out);
        }
      }
      div {
        span {
          margin-bottom: 0;
          font-size: 13px;
          cursor: pointer;
        }
      }
    }
    .post-box {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      span {
        // display: block;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .post-img {
      width: 60px;
      height: 43px;
      display: block;
      margin-right: 20px;
    }
    .input-list {
      margin-bottom: 0;
    }
  }

  .charity-rating-section {
    .tab-box {
      margin-top: 30px;
    }
    .rating-compass-section {
      .Circular-box {
        width: 26%;
        margin: 0 auto 50px;
        &:last-child {
          margin-bottom: 0;
        }
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
        h4 {
          text-align: center;
          color: #389e0d;
          .circular-text {
            display: block;
            line-height: 1;
            font-size: 14px;
            font-weight: 400;
            padding: 0 15px;
          }
        }
      }
      .ratingSeal {
        margin-top: 50px;
        .sealHeading {
          margin-bottom: 10px;
        }
        .sealLogo {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          h4 {
            font-size: 18px;
            line-height: 1;
            margin-left: 20px;
          }
        }
        .seal-question {
          .questionAnswer {
            margin-bottom: 10px;
            margin-top: 15px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          h4 {
            font-size: 16px;
            line-height: 1;
            margin-bottom: 5px;
            text-align: left;
          }
          h5 {
            font-size: 16px;
            line-height: 1;
          }
        }
        h3 {
          font-size: 18px;
          line-height: 1;
        }
      }
      &.star-rating-section {
        .star-box {
          width: 50%;
          margin: 0 auto 50px;
          text-align: center;
          &:first-child {
            width: 100%;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:nth-last-child(2) {
            margin-bottom: 0;
          }
          h4 {
            text-align: center;
            color: #389e0d;
            font-size: 16px;
            line-height: 1;
            margin-top: 5px;
            margin-bottom: 0;
            .circular-text {
              display: block;
              line-height: 1;
              font-size: 15px;
              font-weight: 400;
              padding: 0 15px;
            }
          }
        }
      }
    }
    .panel-box {
      padding-top: 20px;
      h4 {
        margin-bottom: 30px;
        color: #5ba02e;
        font-size: 20px;
        text-align: center;
      }
    }
    .ratingSeal {
      .sealHeading,
      .seal-question {
        h3 {
          font-size: 20px;
          color: #5ba02e;
          margin-bottom: 30px;
        }
      }
    }
    .questionAnswer,
    .sealLogo {
      h4 {
        color: #444a4d;
        font-size: 18px;
      }
    }
  }

  .member-box {
    .post-img {
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .post-inner {
      .post-list {
        margin-top: 30px;
        li {
          display: inline-block;
          vertical-align: top;
          width: 48%;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
            margin-bottom: 0;
          }
          &:nth-child(2n) {
            margin-right: 0;
            justify-content: end;
          }
          &:nth-last-child(2) {
            margin-bottom: 0;
          }
          .box-post {
            background-color: transparent;
            padding: 0;
          }
          .details-list {
            li {
              display: inline-block;
              margin-right: 17px;
              width: auto;
              &:last-child {
                margin-right: 0;
              }
              .samll-title {
                cursor: pointer;
                h5 {
                  background-color: #6daf5f;
                  color: #fff;
                  width: 30px;
                  height: 19px;
                  border: 1px solid transparent;
                  border-radius: 21px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 14px;
                }
              }
            }
          }
          .details-leftbox {
            margin-left: 20px;
          }
        }
        .box-post {
          .title-post {
            .post-img {
              width: 106px;
              height: 66px;
              display: inline-block;
              border-radius: 0;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .samll-title {
              cursor: pointer;
              min-width: 200px;
              h5 {
                display: inline-block;
                font-size: 15px;
                font-weight: 600;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }

  .causes-box {
    padding-top: 0;
    h2 {
      text-align: left;
      height: auto;
    }
  }

  .form-input-box {
    .input-error {
      display: none;
    }
  }
  .inner-post-box {
    margin-top: 20px;
  }
  .upload-model {
    .input-list {
      margin-top: 30px;
    }
    .col-div {
      padding: 0;
    }
    .form-area {
      .form-btn-box {
        .link-btn {
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .input-box {
    height: 40px;
    width: 100%;
    font-family: "Jost",sans-serif;
    font-weight: 400;
    display: inline-block;
    line-height: 35px;
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    text-align: left;
    &.comment-input {
      padding: 10px 145px 10px 10px;
    }
  }
  .main-box-div {
    display: flex;
    align-items: center;
    width: 100%;
    form {
      display: inline-block;
      width: 100%;
    }
    .icon-box {
      display: flex;
      align-items: center;
      margin-left: 20px;
      i {
        margin-right: 10px;
        display: inline-block;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .post-inner {
    .post-list {
      min-height: calc(100vh - 180px);
      overflow: auto;
      li {
        margin-bottom: 25px;
        .comment-bar {
          margin-bottom: 0;
          margin-bottom: 10px;
          background-color: #fff;

          .postCommentAction {
            p a {
              // display: inline-block;
              color: rgba(0, 0, 0, 0.65);
              border-bottom: 1px solid rgba(0, 0, 0, 0.65);
              @include prefix(transition, all 0.4s ease-in-out);

              &:hover {
                color: #1e1e1e;
              }
            }
          }
        }
        &:last-child {
          margin-bottom: 0px;
        }
        &.comment-parent {
          margin-bottom: 0;
        }
        .box-post {
          background-color: rgba(66, 164, 76, 0.1);
          padding: 25px 25px;
          border-radius: 8px;
          .title-post {
            display: flex;
            // justify-content: space-between;
            // margin-bottom: 10px;
            .small-title {
              // width: 450px;
              width: calc(100% - 65px);
              word-break: break-word;
              h5 {
                color: rgba(0, 0, 0, 0.76);
                font-weight: bold;
                line-height: 25px;
                margin-bottom: 3px;
                font-size: 16px;
                word-break: break-word;
              }
              span {
                display: block;
                line-height: 1;
                color: rgba(0, 0, 0, 0.58);
                font-size: 12px;
                margin-bottom: 0;
                word-break: break-word;
              }
            }
            .dots-li {
              cursor: pointer;
              padding: 0 5px;
              li {
                width: 3px;
                height: 3px;
                background-color: #389e0d;
                border-radius: 50%;
                margin-bottom: 3px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
          .inner-postbox-strategic {
            // width: 550px;
            .swiper-pagination {
              display: flex;
              justify-content: center;
              bottom: 20px;
            }
            .swiper-pagination-bullet-active {
              background-color: #389e0d;
            }
            p {
              font-family: 'Jost',sans-serif;
              line-height: 25px;
              color: rgba(0, 0, 0, 0.8);
              // line-height: 1.3;
              margin-bottom: 20px;
              font-size: 14px;
              // word-break: break-all;

              a {
                // display: inline-block;
                line-height: 25px;
                color: rgba(0, 0, 0, 0.65);
                border-bottom: 1px solid rgba(0, 0, 0, 0.65);
                @include prefix(transition, all 0.4s ease-in-out);

                &:hover {
                  color: #1e1e1e;
                }
              }
            }
            .readLess {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .hideBtn {
              display: none;
            }
            .showbutton {
              font-family: 'jost',sans-serif;
              font-weight: 700;
              color: #389e0d;
              background: none;
              cursor: pointer;
              text-align: right;
              display: inline-block;
              width: 100%;
            }
            .post-img {
              width: 100%;
              height: 225px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
              div {
                position: relative;
              }
              .video-react {
                padding-top: 35% !important;
                height: 225px;
                .video-react-control-bar {
                  display: none;
                }
                .video-react-bezel {
                  position: absolute;
                  inset: 0px;
                  margin: auto;
                }
                .video-react-big-play-button {
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                }
              }
            }
          }
          .social-box {
            margin-top: 10px;
            .social-list {
              padding: 0 10px;
              li {
                display: inline-block;
                margin-right: 20px;
                margin-bottom: 0;
                vertical-align: top;
                cursor: pointer;
                &:last-child {
                  margin-right: 0;
                }
              }
              .social-img {
                width: 22px;
                height: 25px;
                display: inline-block;
                position: relative;
                cursor: pointer;
                &.hearts {
                  cursor: pointer;
                  svg {
                    width: 25px;
                    height: 23px;
                    transform: scale(1);
                    animation: bubble 0.3s;
                    cursor: pointer;
                    path {
                      fill: red;
                      stroke: red;
                    }
                  }
                  @keyframes bubble {
                    0% {
                      transform: scale(0.7);
                    }
                    100% {
                      transform: scale(1.3);
                    }
                  }
                }
              }
              span {
                margin-bottom: 0;
                line-height: 1;
                display: inline-block;
                margin-left: 5px;
                font-size: 14px;
                font-weight: 600;
                color: #389e0d;
              }
            }
            .comment-box {
              margin-top: 10px;
              position: relative;
              .btn-text {
                font-weight: 600;
                font-size: 13px;
                color: rgba(66, 164, 76, 0.86);
                background-color: transparent;
                cursor: pointer;
                position: absolute;
                top: 13px;
                right: 15px;
                z-index: 11;
              }
            }
          }
        }
      }
      .inner-comment-box {
        padding-left: 50px;
        .comment-bar {
          margin-bottom: 10px;
          background-color: #fff;
        }
      }
    }
  }
  .post-menu-box {
    position: relative;
    .submenu-list {
      @include prefix(box-shadow, 0px 2px 8px rgba(0, 0, 0, 0.15));
      background-color: #fff;
      width: 142px;
      display: none;
      position: absolute;
      left: -130px;
      top: 20px;
      z-index: 111;
      li {
        margin-bottom: 0;
        display: block;
        a {
          padding: 7px 7px 7px 25px;
          display: block;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          font-weight: 400;
          @include prefix(transition, all 0.3s ease-in-out);
          cursor: pointer;
          &:hover {
            background-color: #5ba02e;
            color: #fff;
          }
        }
      }
      &.open-menu {
        display: block;
      }
    }
  }
  .post-area-box {
    display: inline-block;
    width: 100%;
    .post-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 0;
      h4 {
        line-height: 1;
        color: rgba(66, 164, 76, 0.86);
        font-weight: 500;
      }
      span {
        margin-bottom: 0;
        line-height: 1;
        b {
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}

.react-tabs__tab:focus {
  &:after {
    background-color: transparent;
  }
}

// responsive

@media only screen and (max-width: 1600px) {
  .strategic-profile-section {
    .member-box {
      .post-inner {
        .post-list {
          .box-post {
            .title-post {
              .post-img {
                width: 95px;
                height: 60px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .charity-rating-section {
      .rating-compass-section {
        .Circular-box {
          h4 {
            .circular-text {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1450px) {
  .strategic-profile-section {
    .charity-rating-section {
      .rating-compass-section {
        .Circular-box {
          width: 35%;
        }
      }
    }
  }
}

@media (max-width: 1420px) {
  .strategic-profile-section {
    .rating-box {
      .view-leftbox {
        .view-about-box {
          .profile-box {
            .community-btn {
              width: 145px;
              font-size: 15px;
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .strategic-profile-section {
    .rating-box {
      .view-leftbox {
        .view-about-box {
          .profile-box {
            .community-btn {
              width: 120px;
              font-size: 13px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .strategic-profile-section {
    .rating-box {
      .view-leftbox {
        padding-right: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        border-right: 0;
        padding-bottom: 30px;
      }
      .view-rightbox {
        padding-top: 30px;
      }
    }

    .post-inner {
      .post-list {
        li {
          .box-post {
            .title-post {
              .small-title {
                width: 550px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .strategic-profile-section {
    .post-inner {
      .post-list {
        li {
          .box-post {
            .title-post {
              .small-title {
                width: 520px;
                h5 {
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .strategic-profile-section {
    .rating-box {
      .view-leftbox {
        .info-box {
          .doctor-box {
            padding-right: 0;
            border-right: 0;
          }
        }
      }
    }

    .post-inner {
      .post-list {
        li {
          .box-post {
            .title-post {
              .small-title {
                width: 380px;
                h5 {
                  line-height: 20px;
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .strategic-profile-section {
    .rating-box {
      .profile-list-box {
        flex-wrap: wrap;
      }
      .view-leftbox .add-box .add-list {
        .mail {
          margin-bottom: 10px !important;
        }
      }
    }

    .post-inner {
      .post-list {
        li {
          .box-post {
            .title-post {
              .small-title {
                width: calc(100% - 65px);
                h5 {
                  line-height: 17px;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
    .profile-box {
      .link-btn {
        margin-bottom: 10px !important;
      }
    }
    .image-box {
      .react-tabs__tab-list {
        i {
          top: 40px;
        }
      }
      .post-inner .post-list li {
        margin-bottom: 10px !important;
        width: 100%;
        margin-right: 0 !important;

        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
