@import "../../../scss/style.scss";

.transaction-section {
  .col-div {
    h2 {
      margin-top: 20px;
      font-size: 20px;
    }
  }

  .transWrapperHeader {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: start;
  }
  .transDate {
    display: flex;
    margin-top: 10px;
    margin-right: 10px;
  }
  .div-btn-box {
    margin-bottom: 20px;
    .btn {
      width: auto;
      height: 33px;
      display: flex;
      align-items: center;
      font-family: "Jost",sans-serif;
      border: 1px solid;
      padding: 5px 10px;
      margin-top: 10px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #389e0d;
      background-color: transparent;
    }
    .search-box {
      margin-bottom: 0;
      margin-right: 10px;
      width: 37%;
    }
    .search-input {
      border: 1px solid rgba(0, 0, 0, 0.12);
      width: 100%;
      height: 33px;
      display: inline-block;
      font-weight: 400;
      border-radius: 5px;
      //text-align: left;
      padding: 10px 10px;
    }
  }

  .img-text {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;
    font-size: 13px;
    display: block;
  }
  .profile-list-box {
    display: flex;
    align-items: center;
    .profile-list {
      li {
        display: inline-block;
        margin-left: -10px;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        overflow: hidden;
        border: 3px solid #fff;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    p {
      font-weight: 400;
      padding-left: 5px;
      padding-bottom: 5px;
    }
  }

  .status-box {
    span {
      min-width: 20px;
      border: 1px solid #86ddba;
      border-radius: 11px;
      padding: 3px 5px;
      color: #52c41a;
      text-transform: capitalize;
      line-height: 23px;
      display: inline-block;
      &.reject {
        color: tomato;
        border-color: tomato;
      }
      &.pending {
        color: #2f54eb;
        border-color: #2f54eb;
      }
      &.amount {
        color: #52c41a;
        border: 1px solid #86ddba;
        background-color: #f0fff9;
      }
    }
  }
}
