@import "../../../scss/style.scss";

.view-leftbox {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  padding-right: 50px;
  height: 100%;
  .help-img {
    width: 422px;
    height: 256px;
    margin: 0 auto 50px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  figcaption {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 28px;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    line-height: 30px;
    .star-ratings {
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }
  }
  .view-about-box {
    margin-top: 15px;
    h4 {
      font-weight: 500;
      color: #5ba02e;
      margin-bottom: 15px;
    }
    p {
      color: rgba(0, 0, 0, 0.65);
      font-size: 13px;
      font-weight: 500;
      line-height: 1.3;
      margin-bottom: 15px;
    }
    .member-list {
      li {
        position: relative;
        display: inline-block;
        width: 100%;
        border-radius: 2px;
        padding: 10px 5px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
        span {
          background-color: #389e0d;
          border-radius: 2px;
          border: 1px solid #389e0d;
          padding: 3px 5px;
          display: inline-block;
          color: #fff;
          position: absolute;
          right: 15px;
          top: 15px;
        }
        .dots-div {
          position: relative;
          padding-left: 15px;
          text-align: left;
          &:before {
            content: "";
            border: 1px solid #389e0d;
            border-radius: 50%;
            width: 5px;
            height: 5px;
            display: block;
            position: absolute;
            left: 0;
            top: 6px;
          }
        }
      }
    }
    .community-btn {
      font-family: "Jost",sans-serif;
      width: 256px;
      display: inline-block;
      margin: 0 auto;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
      cursor: pointer;
    }
    .list-member {
      text-align: center;
      h5 {
        text-align: left;
        font-size: 15px;
        color: #6daf5f;
        line-height: 1;
        margin-top: 5px;
      }
    }
  }
}

.view-rightbox {
  .react-tabs__tab-list {
    border-color: #5ba02e;
    position: relative;
    .upload-btns {
      position: absolute;
      right: 0;
      top: 10px;
      font-style: normal;
      font-size: 15px;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .react-tabs__tab--selected {
    border-color: #5ba02e;
    background-color: #fff;
  }
}

.image-box {
  .upload-img {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .btn-play {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .play {
        width: 40px;
        height: 35px;
        margin: auto;
      }
    }
    i {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 70px;
      height: 30px;
      z-index: 1111;
      cursor: pointer;
      opacity: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        width: 30px;
        height: auto;
        margin: auto;
        fill: #fff;
      }
      .delete-btn,
      .play {
        width: 30px;
        height: 30px;
      }
    }
    &:before {
      background-color: rgba(0, 0, 0, 0.7);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      opacity: 0;
      @include prefix(transition, all 0.4s ease-in-out);
    }
    &:hover {
      &:before {
        opacity: 1;
      }
      i {
        opacity: 1;
      }
      .btn-play {
        display: none;
      }
    }
  }
  .post-inner {
    .post-list {
      height: calc(100vh - 245px);
      li {
        display: inline-block;
        width: 49%;
        vertical-align: top;
        margin-right: 10px;
        margin-bottom: 10px;
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

// communityMembers
.communityMembers {
  padding: 30px 0 0;
  .postCommentAction {
    p {
      width: 100%;
      display: block;
      line-height: 1;
      margin-bottom: 10px;
    }
    div {
      span {
        margin-bottom: 0;
        font-size: 13px;
        cursor: pointer;
      }
    }
  }
  .post-box {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    span {
      display: block;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 500;
    }
    .more-btn {
      font-size: 13px;
      padding-left: 70px;
      cursor: pointer;
    }
    .views-btns {
      color: #2f54eb;
      display: inline-block;
      font-weight: 500;
      font-size: 13px;
      margin-left: 15px;
      cursor: pointer;
    }
  }
  .communityPost-img {
    width: 60px;
    height: 43px;
    display: inline-block;
    margin-right: 20px;
    border-radius: 50% !important;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .round-box {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .input-list {
    margin-bottom: 0;
  }
  .memberMainImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .member-inner {
    .post-list {
      li {
        display: inline-flex;
        width: 48%;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
        }
        &:nth-child(2n) {
          margin-right: 0;
          justify-content: start;
        }
        .box-post {
          background-color: transparent;
          padding: 0;
        }
        .small-title {
          width: 215px;
          word-break: break-all;
          h5 {
            color: rgba(0, 0, 0, 0.76);
            font-weight: bold;
            line-height: 1;
            margin-bottom: 3px;
            font-size: 16px;
            word-break: break-all;
          }
          span {
            display: block;
            line-height: 1;
            color: rgba(0, 0, 0, 0.58);
            font-size: 12px;
            margin-bottom: 0;
            word-break: break-all;
          }
        }
        .details-leftbox {
          margin-left: 20px;
        }
      }
    }
  }
}

// post

.form-input-box {
  .input-error {
    display: none;
  }
}
.inner-post-box {
  margin-top: 20px;
}
.upload-model {
  .input-list {
    margin-top: 30px;
  }
  .col-div {
    padding: 0;
  }
  .form-area {
    .form-btn-box {
      .link-btn {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.input-box {
  height: 40px;
  width: 100%;
  font-family: "Jost",sans-serif;
  font-weight: 400;
  display: inline-block;
  line-height: 35px;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  text-align: left;
  &.comment-input {
    padding: 10px 145px 10px 10px;
  }
}
.main-box-div {
  display: flex;
  align-items: center;
  width: 100%;
  form {
    display: inline-block;
    width: 100%;
  }
  .icon-box {
    display: flex;
    align-items: center;
    margin-left: 20px;
    i {
      margin-right: 10px;
      display: inline-block;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.post-main-box {
    .form-input-box {
      position: relative;
    }
    .input-box {
      padding: 10px 50px 10px 10px;
      &.text-area-box {
        height: 100px;
        resize: none;
        text-align: left;
        padding: 13px 45px 5px 10px;
        line-height: 23px;
      }
    }
    .post-btn {
      font-family: "Jost",sans-serif;
      color: #389e0d;
      font-weight: bold;
      font-size: 13px;
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 45px;
    }
  }
}

// post

.post-inner {
  .post-list {
    min-height: calc(100vh - 180px);
    overflow: auto;
    // &::-webkit-scrollbar {
    //     width: 0px;
    //     background: transparent;
    // }
    // &::-webkit-scrollbar {
    //     display: none;
    //   }
    li {
      margin-bottom: 25px;
      .comment-bar {
        margin-bottom: 0;
        margin-bottom: 10px;
        background-color: #fff;

        .postCommentAction{
          p{
            width: 100%;
            a{
             // display: inline-block;
             border-bottom: 1px solid rgba(0, 0, 0, 0.65);
             color: rgba(0, 0, 0, 0.65);
             @include prefix(transition, all 0.4s ease-in-out);
             
 
             &:hover {
               color: #8a8a8a;
             }
           }
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &.comment-parent {
        margin-bottom: 0;
      }
      .box-post {
        background-color: rgba(66, 164, 76, 0.1);
        padding: 25px 25px;
        border-radius: 8px;
        .title-post {
          display: flex;
          // justify-content: space-between;
          margin-bottom: 10px;
          // flex-wrap: wrap;
          .post-img {
            width: 40px;
            height: 40px;
            // border-radius: 50%;
            margin-right: 8px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .small-title {
            // width: calc(100% - 65px);
            word-break: break-all;
            h5 {
              color: rgba(0, 0, 0, 0.76);
              font-weight: bold;
              line-height: 1;
              margin-bottom: 3px;
              font-size: 16px;
              word-break: break-all;
            }
            span {
              display: block;
              line-height: 1;
              color: rgba(0, 0, 0, 0.58);
              font-size: 12px;
              margin-bottom: 0;
              word-break: break-all;
            }
          }
          .dots-li {
            cursor: pointer;
            padding: 0 5px;
            li {
              width: 3px;
              height: 3px;
              background-color: #389e0d;
              border-radius: 50%;
              margin-bottom: 3px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        .inner-postbox {
          .swiper-pagination {
            display: flex;
            justify-content: center;
            bottom: 20px;
          }
          .swiper-pagination-bullet-active {
            background-color: #389e0d;
          }
          p {
            color: rgba(0, 0, 0, 0.65);
            line-height: 1.3;
            margin-bottom: 20px;
            font-size: 14px;
            word-break: break-all;

            a {
              display: inline-block;
              border-bottom: 1px solid rgba(0, 0, 0, 0.65);
              color: rgba(0, 0, 0, 0.65);
              @include prefix(transition, all 0.4s ease-in-out);

              &:hover {
                color: #8a8a8a;
              }
            }
          }
          .readLess {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .hideBtn {
            display: none;
          }
          .showbutton {
            font-family: "Jost",sans-serif;
            font-weight: 700;
            color: #389e0d;
            background: none;
            cursor: pointer;
            text-align: right;
            display: inline-block;
            width: 100%;
          }
          .post-img {
            width: 100%;
            height: 225px;
            overflow: hidden;
            .video-react {
              height: 225px;
              .video-react-control-bar {
                display: none;
              }
              .video-react-bezel {
                position: absolute;
                inset: 0px;
                margin: auto;
              }
              padding-top: 35% !important;
              .video-react-big-play-button {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              }
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            div {
              position: relative;
            }
          }
        }
        .social-box {
          margin-top: 10px;
          .social-list {
            padding: 0 10px;
            li {
              display: inline-block;
              margin-right: 20px;
              margin-bottom: 0;
              vertical-align: top;
              cursor: pointer;
              &:last-child {
                margin-right: 0;
              }
            }
            .social-img {
              width: 22px;
              height: 25px;
              display: inline-block;
              position: relative;
              cursor: pointer;
              &.hearts {
                cursor: pointer;
                svg {
                  width: 25px;
                  height: 23px;
                  transform: scale(1);
                  animation: bubble 0.3s;
                  cursor: pointer;
                  path {
                    fill: red;
                    stroke: red;
                  }
                }
                @keyframes bubble {
                  0% {
                    transform: scale(0.7);
                  }
                  100% {
                    transform: scale(1.3);
                  }
                }
              }
            }
            span {
              margin-bottom: 0;
              line-height: 1;
              display: inline-block;
              margin-left: 5px;
              font-size: 14px;
              font-weight: 600;
              color: #389e0d;
            }
          }
          .comment-box {
            margin-top: 10px;
            position: relative;
            width: 100%;
            .btn-text {
              font-family: "Jost",sans-serif;
              font-weight: 600;
              font-size: 13px;
              color: rgba(66, 164, 76, 0.86);
              background-color: transparent;
              cursor: pointer;
              position: absolute;
              top: 13px;
              right: 15px;
              z-index: 11;
            }
          }
        }
      }
    }
    .inner-comment-box {
      padding-left: 50px;
      .comment-bar {
        margin-bottom: 10px;
        background-color: #fff;
        &.children-comment-bar {
          padding: 10px 15px;
        }
      }
    }
  }
}
.post-menu-box {
  position: relative;
  .submenu-list {
    @include prefix(box-shadow, 0px 2px 8px rgba(0, 0, 0, 0.15));
    background-color: #fff;
    width: 142px;
    display: none;
    position: absolute;
    left: -130px;
    top: 20px;
    z-index: 111;
    li {
      margin-bottom: 0;
      display: block;
      .post-li {
        padding: 7px 7px 7px 25px;
        display: block;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: 400;
        @include prefix(transition, all 0.3s ease-in-out);
        cursor: pointer;
        &:hover {
          background-color: #5ba02e;
          color: #fff;
        }
      }
    }
    &.open-menu {
      display: block;
    }
  }
}
.post-area-box {
  display: inline-block;
  width: 100%;
  .post-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    h4 {
      line-height: 1;
      color: rgba(66, 164, 76, 0.86);
      font-weight: 500;
    }
    span {
      margin-bottom: 0;
      line-height: 1;
      b {
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}

.react-tabs__tab:focus {
  &:after {
    background-color: transparent;
  }
}
// responsive

@media (max-width: 1650px) {
  .communityMembers {
    .post-img {
      margin-right: 8px;
    }
    .wrap-box {
      flex-wrap: nowrap;
    }
  }
  .member-inner {
    .post-list {
      li {
        .small-title {
          width: 180px;
        }
      }
    }
  }
 
}

@media (max-width: 1599px) {
  .view-community {
    .view-leftbox {
      figcaption {
        font-size: 24px;
      }
    }
  }
  .post-inner {
    .post-list {
      li {
        .box-post {
          .inner-postbox {
            .post-img {
              .video-react {
                padding-top: 53% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1419px) {
  .view-community {
    .view-leftbox {
      figcaption {
        font-size: 21px;
      }
    }
  }
  .image-box {
    .post-inner {
      .post-list {
        li {
          margin-right: 8px;
        }
      }
    }
    .upload-img {
      height: 215px !important;
    }
  }
  .communityMembers {
    .member-inner {
      .post-list {
        li {
          .small-title {
            width: 150px !important;
          }
        }
      }
    }
  }
}
@media (max-width: 1366px) and (min-width: 1200px) {
  .post-inner {
    .post-list {
      li {
        .box-post {
          .inner-postbox {
            .post-img {
              .video-react {
                padding-top: 65% !important;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .profile-box{
    display: flex;
    .community-btn {
      font-size: 12px !important;
    }
  }

  .view-community {
    .view-leftbox {
      figcaption {
        flex-wrap: wrap;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .post-inner {
    .post-list {
      li {
        .box-post {
          .title-post {
            .small-title {
              width: calc(100% - 65px);
            }
          }
        }
      }
    }
  }
  .communityMembers {
    .member-inner {
      .post-list {
        li {
          .small-title {
            width: 130px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .view-community {
    .react-tabs__tab {
      padding: 6px 5px;
    }
    .view-leftbox {
      .help-img {
        width: 355px;
        height: 200px;
      }
      figcaption {
        line-height: 30px;
      }
    }
  }
}
@media (max-width: 1199px) {
  .view-community {
    .view-leftbox {
      padding-right: 0px;
      border-right: none;
      margin-bottom: 50px;
    }
  }
  .post-inner {
    .post-list {
      li {
        .box-post {
          .inner-postbox {
            .post-img {
              .video-react {
                padding-top: 32% !important;
              }
            }
          }
        }
      }
    }
  }
  .communityMembers {
    .member-inner {
      .post-list {
        li {
          .small-title {
            width: 180px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .view-community {
    .react-tabs__tab {
      font-size: 14px;
    }
    .view-leftbox {
      .help-img {
        width: 100%;
        height: 250px;
        margin: 0 auto 15px;
      }
      .view-about-box {
        margin-top: 30px;
        h4 {
          line-height: 1;
          margin-bottom: 10px;
        }
      }
    }
    .post-area-box {
      .post-title {
        padding: 18px 0;
      }
    }
  }
  .communityMembers {
    .member-inner {
      .post-list {
        li {
          .small-title {
            width: 150px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .communityMembers {
    .memberMainImg {
      margin-right: 10px;
    }
    .post-inner {
      .post-list {
        li {
          &:nth-last-child(2) {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .view-community {
    .view-rightbox,
    .image-box {
      .react-tabs__tab-list {
        .react-tabs__tab {
          font-size: 14px;
          width: 100% !important;
          text-align: center;
          bottom: 0;
          border-bottom: 1px solid transparent;
          &:last-of-type {
            border-bottom: 0;
          }
        }
        .react-tabs__tab--selected {
          border-bottom-color: #5ba02e;
        }
        .upload-btns {
          top: 75px;
          justify-content: flex-end;
        }
      }
    }
    .view-leftbox {
      height: auto;
      .help-img {
        width: 100%;
        height: 150px;
        margin: 0 auto 15px;
      }
      .view-about-box {
        margin-top: 30px;
        .community-btn {
          width: 100%;
        }
        h4 {
          line-height: 1;
          margin-bottom: 10px;
        }
        .member-list {
          li {
            padding: 20px 9px;
            span {
              font-size: 12px;
              top: 18px;
            }
          }
        }
      }
    }
    .post-area-box {
      .post-title {
        padding: 18px 0;
      }
    }
    .member-inner {
      .post-list {
        li {
          .small-title {
            width: 165px !important;
            h5 {
              font-size: 13px;
            }
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
    .post-inner {
      .post-list {
        li {
          .box-post {
            padding: 25px 15px;
            .small-title {
              h5 {
                font-size: 13px;
              }
              span {
                font-size: 12px;
              }
            }
          }
        }
      }
      &.member-inner {
        .post-list {
          li {
            .box-post {
              padding: 0 !important;
              .title-post {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    .image-box {
      .react-tabs__tab-list {
        .react-tabs__tab {
          width: auto;
          // margin-bottom: 40px;
        }
      }
      .post-inner {
        .post-list {
          li {
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .communityMembers {
      margin-bottom: 25px;
      .post-img {
        margin-right: 5px;
      }
      .post-box {
        span {
          font-size: 12px;
        }
      }
      .post-inner {
        .post-list {
          li {
            width: 100%;
            margin-right: 0;
            &:nth-last-child(2) {
              justify-content: flex-start;
            }
          }
        }
      }
    }
   .post-inner .post-list li:nth-last-child(2) {
      margin-bottom: 25px;
    }
    .view-rightbox {
      .tab-views {
        .react-tabs__tab-list {
          border: 1px solid #5ba02e;
          border-radius: 5px;
          // overflow: hidden;
          li {
            border-bottom: 1px solid #5ba02e;
          }
        }
        .react-tabs__tab--selected {
          border-radius: 0;
          border: 0;
          background-color: #5ba02e;
          color: #fff;
        }
      }
    }
    .image-box .tab-views {
      .react-tabs__tab-list {
        border: 1px solid #5ba02e;
        border-radius: 5px;
        // overflow: hidden;
        li {
          border-bottom: 1px solid #5ba02e;
        }
      }
      .react-tabs__tab--selected {
        border-radius: 0;
        border: 0;
        background-color: #5ba02e !important;
        color: #fff;
      }
    }
  }
}
