@import "../../../scss/style.scss";

.strategic-profile-section {
  .col-boxs {
    padding: 0;
  }
  .form-box {
    padding: 0;
  }
  .button-box {
    .link-btn {
      width: 100px;
      margin-right: 20px;
      border-radius: 0;
      &.cancel {
        background-color: transparent;
        color: #42a44c;
        &:hover {
          background-color: #42a44c;
          color: #fff;
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .top-box {
    h2 {
      height: auto;
    }
  }
  .Profilestep-section {
    .section-step {
      padding-top: 0;
      .steps-main-div {
        padding: 0;
        min-height: unset;
      }
      .col-boxs {
        padding: 0;
      }
    }
  }
  .col-div {
    h2 {
      margin-top: 20px;
      font-size: 20px;
    }
  }
  .img-text {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;
    font-size: 13px;
    display: block;
  }
  .profile-list-box {
    display: flex;
    align-items: center;
    .profile-list {
      li {
        display: inline-block;
        margin-left: -10px;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        overflow: hidden;
        border: 3px solid #fff;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    p {
      font-weight: 400;
      padding-left: 5px;
      padding-bottom: 5px;
    }
  }
  .status-box {
    span {
      min-width: 20px;
      border: 1px solid #86ddba;
      border-radius: 11px;
      padding: 3px 5px;
      color: #52c41a;
      text-transform: capitalize;
      line-height: 23px;
      &.reject {
        color: tomato;
        border-color: tomato;
      }
      &.pending {
        color: #2f54eb;
        border-color: #2f54eb;
      }
    }
  }
  .input-list {
    .input-box {
      text-align: left;
      &.text-area-box {
        height: 100px;
        resize: none;
        text-align: left;
        padding: 5px 10px;
        line-height: 21px;
      }
    }
    ::-webkit-input-placeholder {
      text-align: left;
    }

    ::-moz-placeholder {
      text-align: left;
    }
    ::-ms-input-placeholder {
      text-align: left;
    }
    .inner-form-list {
      .form-grp {
        &.col-grp {
          width: 48%;
          display: inline-block;
          vertical-align: top;
          margin-right: 15px;
          margin-top: 10px;
          &:last-child {
            margin-right: 0;
          }
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
        .input-box {
          // &.select--is-disabled{
          //     padding: 0;
          // }
          // .select__control {
          //     min-height: auto;
          //     height: 20px;
          // }
          .padding-select {
            padding: 0;
          }
        }
      }
    }
  }
  .addressGap{
    margin-bottom: 15px;
  }
  .label-text {
    display: block;
    margin-bottom: 10px;
    color: #223047;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 1;
    span {
      color: red;
      display: inline-block;
      margin-right: 5px;
    }
  }
}

@media (max-width: 1199px) {
  .strategic-profile-section {
    .input-list {
      .inner-form-list {
        .form-grp.col-grp {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}
