@import "../../scss/style.scss";

.event-section {
  .img-col {
    .upload__image-wrapper {
      height: auto;
    }
  }
  .div-btn-box {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    .react-datepicker-wrapper {
      width: auto;
      &:last-child {
        margin-right: 0;
      }
    }
    label {
      display: block;
    }
    .eventMargin {
      margin-right: 20px;
    }
    .btn {
      width: auto;
      height: 33px;
      display: flex;
      align-items: center;
      border: 1px solid;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #389e0d;
      svg {
        width: 15px;
        height: 15px;
        margin-right: 7px;
      }
    }
  }
  .form-area {
    .input-list {
      margin-bottom: 0px;
      .label-text {
        span {
          color: $danger;
        }
      }
      .col-div {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .form-grp {
          display: inline-block;
          width: 100%;
          vertical-align: top;
        }
        .marginClass {
          margin-bottom: 10px;
        }
        .input-box {
          height: 40px;
          width: 100%;
          font-family: "Jost",sans-serif;
          font-weight: 400;
          display: inline-block;
          line-height: 35px;
          padding: 5px 18px;
          border-radius: 5px;
          border: 1px solid #d9d9d9;
          text-align: left;
          &.text-area-box {
            height: 100px;
            resize: none;
            text-align: left;
            padding: 5px 10px;
            line-height: 21px;
          }
        }
      }
      .inner-form-list {
        .form-grp {
          &.col-grp {
            width: 48%;
            display: inline-block;
            vertical-align: top;
            margin-right: 24px;
            margin-top: 10px;
            &:last-child {
              margin-right: 0;
            }
            &:nth-child(even) {
              margin-right: 0;
            }
          }
        }
      }
    }
    .select__placeholder {
      font-size: 13px;
    }
    .inner-div {
      margin-bottom: 0;
      div {
        margin-right: 10px;
        .check-label {
          &.reject {
            color: tomato;
            font-weight: 700;
            .checkmark {
              border-color: tomato;
            }
            input:checked ~ .checkmark {
              background-color: tomato;
            }
          }
          &.approve {
            color: #389e0d;
            font-weight: 700;
          }
        }
      }
    }
    .form-btn-box {
      justify-content: flex-end;
      .link-btn {
        width: 100px;
        margin-right: 20px;
        :last-child {
          margin-right: 0;
        }
        &.cancel {
          background-color: transparent;
          color: #389e0d;
          &:hover {
            background-color: #42a44c;
            color: #ffffff;
          }
        }
        &:hover {
          background-color: transparent;
          color: #389e0d;
        }
      }
    }
  }
  .action-box {
    display: flex;
    padding: 0 15px;
    .btn {
      background-color: transparent;
      border: 1px solid #389e0d;
      width: 27px;
      height: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 10px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      svg {
        width: 15px;
        height: 15px;
      }
      &.wrong {
        border-color: tomato;
        svg {
          fill: tomato;
        }
      }
      &.right {
        svg {
          fill: #389e0d;
        }
      }
    }
  }
  .search-box {
    margin-bottom: 20px;
    .search-input {
      font-family: "Jost",sans-serif;
      border: 1px solid rgba(0, 0, 0, 0.12);
      width: 250px;
      height: 33px;
      display: inline-block;
      font-weight: 400;
      border-radius: 5px;
      //text-align: left;
      padding: 10px 10px;
    }
  }
  .status-box {
    span {
      display: inline-block;
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: 400;
      border-radius: 11px;
      padding: 5px 10px;
      &.up {
        color: #52c41a;
        border: 1px solid #86ddba;
        background-color: #f0fff9;
      }
      &.Past {
        color: #2f54eb;
        background: #f0f5ff;
        border: 1px solid #adc6ff;
      }
      &.reject {
        color: tomato;
        border-color: tomato;
      }
      &.pending {
        color: #2f54eb;
        border-color: #2f54eb;
      }
    }
  }
  .col-div {
    h2 {
      margin-top: 20px;
      font-size: 20px;
    }
  }
  .img-text {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;
    font-size: 13px;
    display: block;
  }
  .profile-list-box {
    display: flex;
    align-items: center;
    .profile-list {
      li {
        display: inline-block;
        margin-left: -10px;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        overflow: hidden;
        border: 3px solid #fff;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    p {
      font-weight: 400;
      padding-left: 5px;
      padding-bottom: 5px;
    }
  }
  .location-box {
    flex-wrap: wrap;
    .profile-list {
      li {
        width: auto;
        height: auto;
        .profile-img {
          width: 23px;
          height: 30px;
        }
      }
    }
    p {
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .checkbox-list {
    .check-label {
      margin-right: 20px;
    }
  }
}

.rating-inner-box {
  .section-steps {
    width: 100%;
    .progressbar-box {
      display: none;
    }
    .btn-boxs {
      display: none;
    }
    .form-box {
      padding: 0;
    }
  }
  .form-area {
    .input-list {
      .col-div {
        .input-box {
          text-align: left;
        }
      }
    }
  }
  .div-checkbox {
    .inner-checkbox.average {
      width: 100%;
    }
  }
  .check-label {
    .checkmark {
      border-radius: 50%;
      width: 18px;
      height: 18px;
      &::after {
        left: 4px;
        top: 1px;
        width: 4px;
        height: 8px;
      }
    }
  }
}

@media (max-width: 1599px) {
  .event-section {
    .form-area {
      .input-list {
        .inner-form-list {
          .form-grp {
            &.col-grp {
              margin-right: 19px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .event-section {
    .form-area {
      .input-list {
        .inner-form-list {
          .form-grp {
            &.col-grp {
              margin-right: 16px;
            }
          }
        }
      }
    }
    .search-input {
      width: 150px;
    }
  }
}

@media (max-width: 1199px) {
  .event-section {
    .search-box {
      .search-input {
        width: 150px;
      }
    }
  }
}

@media (max-width: 767px) {
  .event-section {
    .search-input {
      width: 100px;
    }
    .div-btn-box {
      flex-wrap: wrap;
      .btn {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 386px) {
  .event-section {
    .search-input {
      width: 135px;
    }
    .div-btn-box {
      .btn {
        margin-left: 0;
      }
    }
  }
}
