@import "../../scss/style.scss";

.top-box {
  margin-bottom: 20px;
  h2 {
    text-align: left;
    margin-top: 10px;
    font-size: 25px;
    line-height: 1;
    color: #000;
  }
}
.col-div {
  h2 {
    margin-top: 20px;
    font-size: 20px;
  }
}
.img-text {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
  font-size: 13px;
  display: block;
}
.profile-list-box {
  display: flex;
  align-items: center;
  .profile-list {
    li {
      display: inline-block;
      margin-left: -10px;
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      overflow: hidden;
      border: 3px solid #fff;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  p {
    font-weight: 400;
    padding-left: 5px;
  }
}

.status-box {
  span {
    display: inline-block;
    font-size: 14px;
    min-width: 20px;
    letter-spacing: 1px;
    font-weight: 400;
    border: 1px solid #86ddba;
    border-radius: 11px;
    padding: 3px 5px;
    color: #52c41a;
    text-transform: capitalize;
    line-height: 23px;
    &.amount {
      color: #52c41a;
      border: 1px solid #86ddba;
      background-color: #f0fff9;
    }
    &.reject {
      color: tomato;
      border-color: tomato;
    }
    &.pending {
      color: #2f54eb;
      border-color: #2f54eb;
    }
  }
}
.search-box {
  margin-bottom: 20px;
}
.transaction-section {
  .top-areabox {
    margin-bottom: 20px;
    .search-box {
      margin-bottom: 0;
    }
  }
  .div-btn-box {
    display: flex;
    align-items: flex-end;
    .btn {
      width: auto;
      height: 33px;
      display: flex;
      cursor: pointer;
      align-items: center;
      border: 1px solid;
      padding: 5px 10px;
      margin-top: 10px;
      margin-left: 20px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #389e0d;
      background-color: transparent;
    }
    .export {
      background-color: transparent;
    }
    
    .date-lable {
      display: block;
      margin-bottom: 5px;
      color: #223047;
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
      line-height: 1;
    }
  }
}


.search-input {
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: 250px;
  height: 33px;
  display: inline-block;
  font-weight: 400;
  border-radius: 5px;
  padding: 10px 10px;
}

.action-btn {
  display: flex;
  padding: 0 15px;
  .btn {
    background-color: transparent;
    border: 1px solid #389e0d;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    svg {
      width: 15px;
      height: 15px;
    }
    &.wrong {
      border-color: tomato;
      svg {
        fill: tomato;
      }
    }
    &.right {
      svg {
        fill: #389e0d;
      }
    }
  }
}

.form-area {
  .input-list {
    margin-bottom: 0px;
    .col-div {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      .form-grp {
        display: inline-block;
        width: 100%;
        vertical-align: top;
      }
      .input-box {
        height: 40px;
        width: 100%;
        font-family: "Jost",sans-serif;
        font-weight: 400;
        display: inline-block;
        line-height: 35px;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        text-align: left;
        &.text-area-box {
          height: 100px;
          resize: none;
          text-align: left;
          padding: 5px 10px;
          line-height: 21px;
        }
      }
    }
  }
  .select__placeholder {
    font-size: 13px;
  }
  .inner-div {
    margin-bottom: 0;
    div {
      margin-right: 10px;
      .check-label {
        &.reject {
          color: tomato;
          font-weight: 700;
          .checkmark {
            border-color: tomato;
          }
          input:checked ~ .checkmark {
            background-color: tomato;
          }
        }
        &.approve {
          color: #389e0d;
          font-weight: 700;
        }
      }
    }
  }
  .form-btn-box {
    justify-content: flex-end;
    .link-btn {
      width: 100px;
      margin-right: 20px;
      :last-child {
        margin-right: 0;
      }
      &.cancel {
        background-color: transparent;
        color: #389e0d;
        &:hover {
          background-color: #42a44c;
          color: #ffffff;
        }
      }
      &:hover {
        background-color: transparent;
        color: #389e0d;
      }
    }
  }
}

.rating-inner-box {
  .section-steps {
    width: 100%;
    .progressbar-box {
      display: none;
    }
    .btn-boxs {
      display: none;
    }
    .form-box {
      padding: 0;
    }
  }
  .form-area {
    .input-list {
      .col-div {
        .input-box {
          text-align: left;
        }
      }
    }
  }
  .div-checkbox {
    .inner-checkbox.average {
      width: 100%;
    }
  }
  .check-label {
    .checkmark {
      border-radius: 50%;
      width: 18px;
      height: 18px;
      &::after {
        left: 4px;
        top: 1px;
        width: 4px;
        height: 8px;
      }
    }
  }
}
