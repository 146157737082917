@import "../../../scss/style.scss";

.video-box {
  .image-item__btn-wrapper {
    position: relative;
    display: block;
    top: unset;
    left: unset;
    width: auto;
    height: auto;
    &:before {
      display: none;
    }
  }
}
.image-item {
  display: flex;
  margin: 10px 10px 10px 0;
  height: 104px;
  width: 104px;
  border-radius: 5px;
  border: 2px dotted #42a44c;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  :nth-child(2n + 1) {
    margin-right: 0;
  }
  &:hover .image-item__btn-wrapper {
    display: flex;
  }
}
.image-item__btn-wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include prefix(transition, all 0.4s ease-in-out);
  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .img-btn {
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    // border-radius: 2px;
    margin-right: 11px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    position: relative;
    z-index: 11;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 1;
    svg {
      height: 20px;
      width: 20px;
      color: #42a44c;
    }
    // &.remove{
    //     background-color: red;
    // }
    &:last-child {
      margin-right: 0;
    }
  }
}
.img-map-box {
  display: flex;
  // flex-wrap: wrap;
}
.upload__image-wrapper {
  display: flex;
  flex-wrap: wrap;
  height: 250px;
  overflow: auto;
}
.imageModalSet {
  display: flex;
  flex-wrap: wrap;
  height: 250px;
  overflow: auto;
}
.upload-btn {
  margin: 10px 10px 10px 0;
  background-color: transparent;
  border: 2px dotted #42a44c;
  color: #42a44c;
  border-radius: 5px;
  overflow: hidden;
  font-size: 35px;
  display: inline-block;
  font-weight: 400;
  height: 104px;
  width: 104px;
  padding: 0 10px;
  cursor: pointer;
}

.upload-model {
  .modele-text-box {
    padding: 0 19px;
  }
}
