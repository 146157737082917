@import "../../scss/style.scss";

/* width */
// ::-webkit-scrollbar {
//   width: 6px;
// }

/* Track */
// ::-webkit-scrollbar-track {
//   background: white;
// }

/* Handle */
// ::-webkit-scrollbar-thumb {
//   border-radius: 5px;
//   background: #888;
// }

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: rgb(58, 57, 57);
// }
.reverse-scroll-wrapper {
  max-height: calc(100vh - 262px);
  min-height: calc(100vh - 305px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
}
.scroll-wrapper {
  max-height: calc(100vh - 310px);
  overflow: auto;
}
.border-50 {
  border-radius: 50%;
}
.chat-wrap {
  display: flex;
  flex-wrap: wrap;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 0;
  overflow: hidden;
  min-height: calc(100vh - 176px);
  //@include prefix (border-radius, 10px);
}

.page-heading {
  h2 {
    text-align: left;
    color: #389e0d;
    font-family: "Jost",sans-serif;
  }
}

.chat-list-wrap {
  width: 300px;
  max-width: 100%;
  border-right: 1px solid #d9d9d9;
  .search-box {
    width: 100%;
    text-align: center;
    padding-top: 10px;
    .search-input {
      width: 90%;
      margin: 0 auto;
    }
  }
  h4 {
    font-size: 20px;
    padding: 20px 20px;
    display: block;
    height: 69px;
    border-bottom: 1px solid #d9d9d9;
  }
}
h2 {
  height: 55px;
  text-align: left;
  font-weight: 400;
}

.chat-listing {
  width: 100%;

  li {
    &:last-child {
      margin-bottom: 0;
    }

    .chat-user {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 10px 15px;
      cursor: pointer;

      .rosiChatWrap {
        display: flex;
      }

      &:hover {
        background-color: #a5d0a1;
      }

      &.active-chat {
        background-color: #a5d0a1;

        .chat-nm-desc {
          strong {
            font-weight: 700;
          }

          p {
            color: #1e1e1e;
          }
        }
      }

      figure {
        height: 40px;
        width: 40px;
        margin-right: 10px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .chat-nm-desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 60px);
        max-width: 100%;
        strong {
          font-family: "Jost",sans-serif;
          color: #1e1e1e;
          font-size: 13px;
          display: inline-block;
          width: 100%;
          font-weight: 400;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        p {
          font-family: "Jost",sans-serif;
          font-size: 12px;
          width: 135px;
          font-weight: 400;
          color: #525252;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .rosiTime {
        display: flex;
        align-items: end;
      }

      .chat-time {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        p {
          font-family: "Jost",sans-serif;
          font-weight: 400;
          font-size: 10px;
          padding-bottom: 4px;
        }
        span {
          font-family: "Jost",sans-serif;
          font-weight: 400;
          font-size: 10px;
        }
      }
    }
  }
}

.notReadList .chat-user .rosiChatWrap {
  .chat-nm-desc {
    strong {
      font-weight: 700;
      color: black;
    }

    p {
      font-weight: 700;
    }
  }
}
.chat-body-wrap {
  width: calc(100% - 300px);
  max-width: 100%;
  background-color: transparent;
  .input-box {
    height: 55px;
    width: 100%;
    font-family: "Jost",sans-serif;
    font-weight: 400;
    display: inline-block;
    line-height: 35px;
    padding: 10px 100px 10px 20px;
    border-radius: 0;
    border: 1px solid #d9d9d9;
    border-bottom: none;
    border-right: none;
  }
}

.chat-body-header {
  padding: 9px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border-bottom: 1px solid #d9d9d9;
  .active-chat-user {
    display: flex;
    align-items: center;
    width: calc(100% - 30px);
    max-width: 100%;

    figure {
      height: 50px;
      width: 50px;
      margin-right: 10px;

      img {
        height: 100%;
        width: 100%;
      }
    }
    .menu-box {
      position: relative;
      .dots-li {
        cursor: pointer;
        li {
          color: black;
          width: 3px;
          height: 3px;
          background-color: black;
          border-radius: 50%;
          margin-bottom: 2px;
        }
      }

      .submenu-list {
        @include prefix(box-shadow, 0px 2px 8px rgba(0, 0, 0, 0.15));
        background-color: #fff;
        width: 142px;
        position: absolute;
        left: -130px;
        top: 20px;
        z-index: 111;
        li {
          margin-bottom: 0;
          display: block;
          .chat-li {
            padding: 7px 7px 7px 25px;
            display: block;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-weight: 400;
            @include prefix(transition, all 0.3s ease-in-out);
            cursor: pointer;
            &:hover {
              background-color: #5ba02e;
              color: #fff;
            }
          }
        }
        &.open-menu {
          display: block;
        }
      }
    }
    .chat-nm-desc {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      strong {
        font-family: "Jost",sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
      }
    }
  }
}
.chat-placeholder {
  width: calc(100% - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-dot {
  display: inline-block;
  cursor: pointer;
  height: 20px;
  width: 20px;

  .anticon-more {
    display: inline-block;
    font-size: 20px;
  }
}

.chat-body-main {
  width: 100%;

  .chat-body {
    padding: 15px;

    .chat-msg {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;

      figure {
        height: 42px;
        width: 42px;
        margin-right: 15px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .chat-content {
        display: inline-block;
        width: calc(75% - 57px);

        span {
          color: $white;
          background-color: #d33520;
          padding: 10px 15px;
          border-radius: 0px 30px 30px 30px;
          display: inline-block;

          a {
            word-break: break-all;
            color: white;
            border-bottom: 1px solid white;
          }
        }

        small {
          display: block;
          margin-top: 5px !important;
        }
      }

      &.sender {
        flex-direction: row-reverse;

        figure {
          height: 42px;
          width: 42px;
          margin-right: 0;
          margin-left: 15px;
        }

        .chat-content {
          text-align: right;

          span {
            color: $white;
            background-color: #000000;
            border-radius: 30px 30px 0 30px;
            text-align: left;

            a {
              color: white;
              border-bottom: 1px solid white;
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.custom-file-upload {
  cursor: pointer;

  label {
    cursor: pointer;
  }

  input[type="file"] {
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
}

.chat-footer {
  position: relative;

  .ant-input {
    padding-right: 90px;
  }

  .chat-action {
    position: absolute;
    right: 15px;
    top: 15px;

    button {
      border: 0;
      cursor: pointer;
      margin-right: 10px;
      font-size: 24px;
      line-height: 0;
      width: 32px;
      padding: 0;
      background-color: transparent;
      svg {
        fill: rgba(66, 164, 76, 0.85);
        width: 20px;
        height: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.chat-back {
  font-size: 20px;
  margin-right: 5px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #f0f7ef;
  line-height: 0;
  display: none;
  svg {
    transform: rotate(270deg);
  }
}

// @media (max-width: 1199px) {
//   .chat-placeholder {
//     // width: 58%;
//   }
// }

// @media (max-width: 991px) {
//   .chat-placeholder {
//     // width: 55%;
//   }
// }

@media (max-width: 767px) {
  .chat-wrap {
    .chat-back {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .chat-list-wrap {
      width: 100%;
      &.inactive {
        display: none;
      }
    }

    .chat-body-wrap {
      width: 100%;
      display: none;

      &.active {
        display: block;
      }

      .chat-msg {
        .chat-content {
          width: calc(100% - 57px);
        }
      }
    }
    .chat-placeholder {
      display: none;
    }
  }
}
