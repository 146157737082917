@import "../../../scss/style.scss";

.section-div {
  min-height: 100vh;
  strong {
    width: 100%;
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    padding: 0 10px;
    margin-bottom: 10px;
  }

  .div-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: calc(100vh - 37px);
  
    &.container{
      width: 1480px !important;
    }
  }
}



.mainLogo {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
}

.signup-section {
  strong {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .input-list {
    margin-bottom: 22px;
    li {
      margin-bottom: 28px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      .tooltip-info{
        position: absolute;
        top: 8px;
        right: -5%;
        cursor: pointer;
      }
      .form-grp {
        display: inline-block;
        width: 100%;
        vertical-align: top;
        position: relative;
        &::before {
          content: "";
          background-repeat: no-repeat;
          background-size: auto;
          width: 18px;
          height: 17px;
          display: block;
          position: absolute;
          left: 15px;
          top: 13px;
          bottom: 0;
        }
      }
      .password::before {
        top: 10px;
        height: 20px;
      }
      .build::before {
        height: 20px;
        top: 10px;
      }
      .input-box {
        height: 40px;
        width: 100%;
        font-family: "Jost",sans-serif;
        font-weight: 400;
        display: inline-block;
        line-height: 35px;
        padding: 10px 20px;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        text-align: center;
      }
      ::-webkit-input-placeholder {
        text-align: center;
      }
      :-moz-placeholder {
        text-align: center;
      }
      ::-moz-placeholder {
        text-align: center;
      }
      :-ms-input-placeholder {
        text-align: center;
      }
    }
  }
  .address-terms {
    margin-bottom: 30px;
    margin-left: 25px;

    .rememberReg {
      justify-content: start;
      text-transform: capitalize;
    }
  }
  .bottom-text {
    text-align: center;
    display: block;
    color: $theme-color;
    font-weight: 400;
    font-size: 14px;
    margin-top: 35px;
    a {
      display: inline-block;
      font-weight: 700;
      color: $theme-color;
    }
  }

  .link-btn {
    display: inline-block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-family: "Jost",sans-serif;
    background-color: $theme-color;
    color: $white;
    @include prefix(box-shadow, 0px 2px 0px rgba(0, 0, 0, 0.043));
    @include prefix(transition, all 0.4s ease-in-out);
    border: 1px solid #42a44c;
    border-radius: 2px;
    font-weight: 400;
    font-size: 16px;
    &:hover {
      background-color: transparent;
      color: #42a44c;
    }
  }
  .inner-div {
    margin-bottom: 25px;
  }

  .form-box {
    padding: 0;
    width: 400px;
    margin: 0 auto;
    .signHeader {
      display: inline-block;
      width: 100%;
      font-size: 28px;
      color: rgba(66, 164, 76, 0.85);
      margin-bottom: 20px;
      font-weight: 700;
      text-align: center;
    }
  }

  .figure-img {
    width: 100%;
  max-width: 700px;

    // width: 425px;
    // height: 450px;
    // display: block;
    // margin: auto;
  }
}

// check-box
.check-label {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  @include prefix(user-select, none);

  h5 a{
    color: #42a44c;
}

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ .checkmark:after {
        display: block;
      }
      ~ .checkmark {
        background-color: $theme-color;
      }
    }
  }

  .checkmark {
    &:after {
      left: 3px;
      top: 0px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      @include prefix(transform, rotate(45deg));
    }
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid $theme-color;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}

.strategicButton {
  margin-top: 15px;
}

@media (max-width: 1400px) {
  .signup-section {
    .form-box {
      width: 380px;
      .signHeader {
        font-size: 26px;
        margin-bottom: 18px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .signup-section {
    .form-box {
      width: 350px;
      .signHeader {
        font-size: 24px;
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: 992px) {
  .signup-section {
    .figure-img {
      width: 350px;
      height: 370px;
    }
    .form-box {
      width: 350px;
      .signHeader {
        font-size: 22px;
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 767px) {
  .signup-section {
    .none-mobile {
      display: none;
    }
    .form-box {
      padding: 0;
    }
    .input-list li {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 576px) {
  .signup-section {
    .input-list li .input-box {
      font-size: 12px;
      padding: 10px 15px;
    }
    .input-list li .form-grp::before {
      left: 10px;
    }
    ::placeholder {
      font-size: 12px;
    }
    .form-box {
      width: 290px;
    }
  }
}
