@import '../../../../scss/style.scss';

.community-box{
    padding: 30px 0 0;
    &.profile-post{

    
    .post-box{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        span{
            display: block;
            margin-bottom: 5px;
            font-size: 17px;
            font-weight: 500;
        }
    }
    .post-img{
        width: 60px;
        height: 43px;
        display: block;
        margin-right: 20px;
    }
    .input-list{
        margin-bottom: 0;
    }
    .main-box-div{
        display: flex;
        align-items: center;
        width: 100%;
        form{
            display: inline-block;
            width: 100%;
        }
        .icon-box{
            display: flex;
            align-items: center;
            margin-left: 20px;
            i{
                margin-right: 10px;
                display: inline-block;
                cursor: pointer;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        &.post-main-box{
            .form-input-box{
                position: relative;
            }
            .input-box{
                padding: 10px 50px 10px 10px
            }
            .post-btn{
                font-family: 'Jost',sans-serif;
                color: #389E0D;
                font-weight: bold;
                font-size: 13px;
                background-color: transparent;
                cursor: pointer;
                position: absolute;
                right: 15px;
                top: 13px;
            }
        }
        
    }
    
    .post-inner{
        .post-list{
            height: calc(100vh - 455px);
            overflow: auto;
            li{
                margin-bottom: 25px;
                &:last-child{
                    margin-bottom: 0;
                }
                .box-post{
                     background-color: rgba(66, 164, 76, 0.1);
                     padding: 25px 25px;
                     border-radius: 8px;
                    .title-post{
                        display: flex;
                        justify-content: space-between;
                        .samll-title{
                            h5{
                                color: rgba(0, 0, 0, 0.76);
                                font-weight: 500;
                                line-height: 1;
                                margin-bottom: 3px;
                                font-size: 16px;
                            }
                            span{
                                display: block;
                                line-height: 1;
                                color: rgba(0, 0, 0, 0.58);
                                font-size: 14px;
                                margin-bottom: 0;
                            }
                        }
                        .dots-li{
                            cursor: pointer;
                            padding: 0 5px;
                            li{
                                width: 3px;
                                height: 3px;
                                background-color: #389E0D;
                                border-radius: 50%;
                                margin-bottom: 3px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .inner-postbox{
                        p{
                            color: rgba(0, 0, 0, 0.65);
                            line-height: 1.3;
                            margin-bottom: 20px;
                            font-size: 13px;
                        }
                        .post-img{
                            width: 100%;
                            height: 208px;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                            div{
                                position: relative;
                            }
                            .video-react{
                                padding-top: 27% !important;
                                .video-react-big-play-button{
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    margin: auto;
                                }
                            }
                        }
                    }
                    .social-box{
                        margin-top: 10px;
                        .social-list{
                            padding: 0 10px;
                            li{
                                display: inline-block;
                                margin-right: 20px;
                                margin-bottom: 0;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                            .social-img{
                                width: 22px;
                                height: 25px;
                                display: inline-block;
                            }
                            span{
                                margin-bottom: 0;
                                line-height: 1;
                                display: inline-block;
                                margin-left: 5px;
                                font-size: 14px;
                                font-weight: 600;
                                color: #389E0D;
                            }
                        }
                        .comment-box{
                            margin-top: 10px;
                            position: relative;
                            .btn-text{
                                font-weight: 600;
                                font-size: 13px;
                                color: rgba(66, 164, 76, 0.86);
                                background-color: transparent;
                                cursor: pointer;
                                position: absolute;
                                top: 13px;
                                right: 15px;
                                z-index: 11;
                            }
                        }
                    }
                }
            }
        }
    }
    .post-menu-box{
        position: relative;
        .submenu-list{
            @include prefix (box-shadow,  0px 2px 8px rgba(0, 0, 0, 0.15));
            background-color: #fff;
            width: 142px;
            display: none;
            position: absolute;
            left: -130px;
            top: 20px;
            z-index: 111;
            li{
                margin-bottom: 0;
                display: block;
                .post-li{
                    padding: 7px 7px 7px 25px;
                    display: block;
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 14px;
                    font-weight: 400;
                    @include prefix (transition, all 0.3s ease-in-out);
                    cursor: pointer;
                    &:hover{
                        background-color: #5BA02E;
                        color: #fff;
                    }
                }
            }
            &.open-menu{
                display: block;
            }
        }
    }
    .post-area-box{
        display: inline-block;
        width: 100%;
        .post-title{
            display: flex;
            justify-content: space-between;
            align-items: center; 
            padding: 25px 0;
            h4{
                line-height: 1;
                color: rgba(66, 164, 76, 0.86);
                font-weight: 500;
            }
            span{
                margin-bottom: 0;
                line-height: 1;
            }
        }
    }
    .inner-post-box{
        margin-top: 20px;
    }
    .upload-model{
        .input-list{
            margin-top: 30px;
        }
        .col-div{
            padding: 0;
        }
        .form-area{
            .form-btn-box {
                .link-btn{
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
    }    
}