@import "../../../scss/style.scss";

.event-view {
  .view-inner-box {
    width: 100%;
  }
  .create-img-box {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .list-member {
    margin-bottom: 30px;
    h4 {
      margin-bottom: 20px;
      line-height: 1;
      font-size: 23px;
    }
    .title-img-box {
      h5 {
        padding-top: 10px;
        margin-left: 20px;
        font-size: 20px;
        line-height: 1;
        span {
          display: block;
          font-size: 13px;
          line-height: 1;
          margin-bottom: 5px;
          font-weight: bold;
        }
      }
    }
  }
  .event-info-box {
    margin-bottom: 20px;
    div {
      margin-bottom: 5px;
      & :last-child {
        margin-bottom: 0;
      }
      .label-text {
        font-weight: bold;
        display: inline-block;
      }
    }
  }
  .add-box {
    .add-list {
      margin-bottom: 10px;
      li {
        display: inline-block;
        margin-bottom: 15px;
        margin-right: 6px;
        padding-left: 25px;
        position: relative;
        vertical-align: top;
        width: 100%;
        line-height: 1;
        span,
        a {
          color: rgba(0, 0, 0, 0.65);
          display: inline-block;
          font-weight: 400;
          line-height: 1;
        }
        a {
          border-bottom: 1px solid rgba(0, 0, 0, 0.65);
          transition: all 0.4s ease-in-out;
          padding-bottom: 2px;
          &:hover {
            color: #1e1e1e;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:before {
          background-repeat: no-repeat;
          background-size: contain;
          content: "";
          display: block;
          height: 18px;
          left: 0;
          position: absolute;
          top: 0;
          width: 18px;
        }
        &.phone {
          width: 100%;
          // a {
          //   color: rgba(0, 0, 0, 0.65);
          //   font-weight: 400;
          // }
          &:before {
            @include prefix(transform, rotate(115deg));
          }
        }
      }
    }
    p {
      position: relative;
      padding-left: 25px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      a {
        color: rgba(0, 0, 0, 0.65);
        display: inline-block;
        font-weight: 700;
        line-height: 1;
        border-bottom: 1px solid rgba(0, 0, 0, 0.65);
        transition: all 0.4s ease-in-out;
        padding-bottom: 3px;
        &:hover {
          color: #1e1e1e;
        }
      }
      &:before {
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        display: block;
        height: 18px;
        left: 0;
        position: absolute;
        top: 0;
        width: 18px;
      }
    }
  }

  .input-list {
    display: flex;
    width: 100%;
    li {
      margin-right: 15px;
      margin-bottom: 0;
      width: 49%;
      &:last-child {
        margin-right: 0;
      }
      .label-text {
        padding-left: 5px;
      }
      .input-box {
        text-align: left;
        padding: 10px 10px;
      }
      ::-webkit-input-placeholder {
        text-align: left;
      }
      :-moz-placeholder {
        text-align: left;
      }
      ::-moz-placeholder {
        text-align: left;
      }
      :-ms-input-placeholder {
        text-align: left;
      }
    }
  }

  .view-leftbox {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    padding: 0 50px 50px 0;
    height: 100%;
    .help-img {
      width: 450px;
      height: 256px;
      margin: 0 auto 30px;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    figcaption {
      display: flex;
      align-items: center;
      font-size: 28px;
      font-weight: 500;
      justify-content: center;
      line-height: 5px;
      .star-ratings {
        margin-left: 20px;
      }
    }
    .view-about-box {
      margin-top: 50px;
      h4 {
        font-weight: 500;
        color: #5ba02e;
        margin-bottom: 15px;
      }
      p {
        color: rgba(0, 0, 0, 0.65);
        font-size: 13px;
        font-weight: 500;
        line-height: 1.3;
        margin-bottom: 15px;
      }
      .member-list {
        li {
          border: 1px solid rgba(66, 164, 76, 0.86);
          position: relative;
          display: inline-block;
          width: 100%;
          border-radius: 2px;
          padding: 20px 25px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 20px;
          // &:last-child{
          //     margin-bottom: 0;
          // }
          span {
            background-color: #389e0d;
            border-radius: 2px;
            border: 1px solid #389e0d;
            padding: 3px 5px;
            display: inline-block;
            color: #fff;
            position: absolute;
            right: 15px;
            top: 15px;
          }
          .dots-div {
            position: relative;
            padding-left: 15px;
            text-align: left;
            &:before {
              content: "";
              border: 1px solid #389e0d;
              border-radius: 50%;
              width: 5px;
              height: 5px;
              display: block;
              position: absolute;
              left: 0;
              top: 6px;
            }
          }
        }
      }
      .community-btn {
        width: 256px;
        display: inline-block;
        margin: 0 auto;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
      }
      .list-member {
        text-align: center;
      }
    }
  }

  .view-rightbox {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding-top: 30px;
    .member-btn {
      font-family: "Jost",sans-serif;
      width: 150px;
      margin-left: auto;
      margin-bottom: 10px;
      display: block;
      cursor: pointer;
    }
    h2 {
      text-align: left;
      margin-bottom: 20px;
      line-height: 1;
      height: auto;
      font-size: 27px;
      color: #42a44c;
      font-family: "Jost",sans-serif;
    }
    .react-tabs__tab-list {
      border-bottom: none;
      text-align: center;
      border-color: #5ba02e;
    }
    .react-tabs__tab--selected {
      border-color: #5ba02e;
      background-color: #fff;
      border-bottom: 2px solid #389e0d;
      border-left: transparent;
      border-top: transparent;
      border-right: transparent;
    }
  }
}

.image-box {
  .upload-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .post-inner {
    .post-list {
      height: calc(100vh - 320px);
      li {
        display: inline-block;
        width: 49%;
        vertical-align: top;
        margin-right: 10px;
        margin-bottom: 10px;
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}


.member-box {
  .post-img {
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .post-inner {
    .post-list {
      li {
        display: inline-flex;
        width: 49%;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
        }
        &:nth-child(2n) {
          margin-right: 0;
          justify-content: end;
        }
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
        .box-post {
          background-color: transparent;
          padding: 0;
        }
        .details-list {
          li {
            display: inline-block;
            margin-right: 17px;
            width: auto;
            &:last-child {
              margin-right: 0;
            }
            .samll-title {
              cursor: pointer;
              h5 {
                background-color: #6daf5f;
                color: #fff;
                width: 30px;
                height: 19px;
                border: 1px solid transparent;
                border-radius: 21px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
              }
            }
          }
        }
        .details-leftbox {
          margin-left: 20px;
        }
      }
    }
  }
}

.form-input-box {
  .input-error {
    display: none;
  }
}
.inner-post-box {
  margin-top: 20px;
}
.upload-model {
  .input-list {
    margin-top: 30px;
  }
  .col-div {
    padding: 0;
  }
  .form-area {
    .form-btn-box {
      .link-btn {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.input-box {
  height: 40px;
  width: 100%;
  font-family: "Jost",sans-serif;
  font-weight: 400;
  display: inline-block;
  line-height: 35px;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  text-align: left;
  &.comment-input {
    padding: 10px 145px 10px 10px;
  }
}
.main-box-div {
  display: flex;
  align-items: center;
  width: 100%;
  form {
    display: inline-block;
    width: 100%;
  }
  .icon-box {
    display: flex;
    align-items: center;
    margin-left: 20px;
    i {
      margin-right: 10px;
      display: inline-block;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.post-inner {
  .post-list {
    height: calc(100vh - 225px);
    overflow: auto;
    // &::-webkit-scrollbar {
    //     width: 0px;
    //     background: transparent;
    // }
    // &::-webkit-scrollbar {
    //     display: none;
    //   }
    li {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      .box-post {
        background-color: rgba(66, 164, 76, 0.1);
        padding: 25px 25px;
        border-radius: 8px;
        .title-post {
          display: flex;
          justify-content: space-between;
          .samll-title {
            h5 {
              color: rgba(0, 0, 0, 0.76);
              font-weight: 500;
              line-height: 1;
              margin-bottom: 3px;
              font-size: 16px;
            }
            span {
              display: block;
              line-height: 1;
              color: rgba(0, 0, 0, 0.58);
              font-size: 14px;
              margin-bottom: 0;
            }
          }
          .dots-li {
            cursor: pointer;
            padding: 0 5px;
            li {
              width: 3px;
              height: 3px;
              background-color: #389e0d;
              border-radius: 50%;
              margin-bottom: 3px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        .inner-postbox {
          p {
            color: rgba(0, 0, 0, 0.65);
            line-height: 1.3;
            margin-bottom: 20px;
            font-size: 13px;
          }
          .post-img {
            width: 100%;
            height: 208px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .social-box {
          margin-top: 10px;
          .social-list {
            padding: 0 10px;
            li {
              display: inline-block;
              margin-right: 20px;
              margin-bottom: 0;
              &:last-child {
                margin-right: 0;
              }
            }
            .social-img {
              width: 22px;
              height: 25px;
              display: inline-block;
            }
            span {
              margin-bottom: 0;
              line-height: 1;
              display: inline-block;
              margin-left: 5px;
              font-size: 14px;
              font-weight: 600;
              color: #389e0d;
            }
          }
          .comment-box {
            margin-top: 10px;
            position: relative;
            .btn-text {
              font-weight: 600;
              font-size: 13px;
              color: rgba(66, 164, 76, 0.86);
              background-color: transparent;
              cursor: pointer;
              position: absolute;
              top: 13px;
              right: 15px;
              z-index: 11;
            }
          }
        }
      }
    }
  }
}
.post-menu-box {
  position: relative;
  .submenu-list {
    @include prefix(box-shadow, 0px 2px 8px rgba(0, 0, 0, 0.15));
    background-color: #fff;
    width: 142px;
    display: none;
    position: absolute;
    left: -130px;
    top: 20px;
    z-index: 111;
    li {
      margin-bottom: 0;
      display: block;
      a {
        padding: 7px 7px 7px 25px;
        display: block;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: 400;
        @include prefix(transition, all 0.3s ease-in-out);
        &:hover {
          background-color: #5ba02e;
          color: #fff;
        }
      }
    }
    &.open-menu {
      display: block;
    }
  }
}
.post-area-box {
  display: inline-block;
  width: 100%;
  .post-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    h4 {
      line-height: 1;
      color: rgba(66, 164, 76, 0.86);
      font-weight: 500;
    }
    span {
      margin-bottom: 0;
      line-height: 1;
    }
  }
}

.react-tabs__tab {
  &:focus {
    &:after {
      display: none;
    }
  }
}

// responsive

@media (max-width: 1366px) {
  .event-view {
    .view-leftbox {
      padding: 0 50px 25px 0px;
      .help-img {
        width: 340px;
        height: 200px;
      }
    }
    .add-box {
      .add-list {
        li {
          width: 100%;
          margin-right: 0;
        }
      }
    }
    .col-box {
      padding: 0;
    }
  }
}

@media (max-width: 1199px) {
  .event-view {
    .view-leftbox {
      padding-right: 15px;
      .help-img {
        width: 265px;
        height: 170px;
      }
    }
    .view-rightbox {
      padding-top: 20px;
      h2 {
        margin-bottom: 10px;
      }
      .community-box {
        padding-top: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .event-view {
    .view-leftbox {
      padding-right: 0;
      border-right: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
    .right-inner-box {
      padding-top: 20px;
      margin: 0 -15px 20px;
    }
  }
}

@media (max-width: 576px) {
  .event-view {
    .react-tabs__tab {
      font-size: 14px;
    }
  }
}
