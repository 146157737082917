@import "../../../scss/style.scss";

.sEmployeeHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .search-box {
    margin-bottom: 0;
  }
}

.image-box {
  padding-top: 20px;
  .react-tabs__tab-list {
    border-bottom: none;
    text-align: center;
    margin-bottom: 20px;
  }
  .react-tabs__tab--selected {
    bottom: 0;
    border: 2px solid #5ba02e !important;
    background: transparent !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
  }
  .react-tabs__tab {
    border: 2px solid transparent;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    &:focus {
      &:after {
        display: none;
      }
    }
  }
  .upload-img {
    height: 250px !important;
  }
}

.tabs-boxs-area {
  .btn {
    background-color: transparent;
    cursor: pointer;
  }
}

@media (max-width: 576px) {
  .sEmployeeHeader {
    margin-bottom: 15px;

    .search-box {
      width: 100%;
      margin-bottom: 15px;
      input {
        width: 100%;
      }
    }
  }
}
