h2 {
    font-size: 33px;
    font-family: 'Lora-Bold',sans-serif;
}

h3 {
    font-size: 32px;
}
h4 {
    font-size: 20px;
}
p {
    font-size: 14px;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-success {
    color: $success;
}

.text-danger {
    color: $danger;
    margin-top: 5px;
    line-height: 1;
}

.text-warning {
    color: $warning;
}

.text-info {
    color: $info;
}

.text-minute {
    color: $color2;
}

.position-relative {
    position: relative;
}

// Font Family


// Width css
.w-100 {
    width: 100%;
}

// Height SCSS
.min-h100 {
    min-height: 100vh;
}

.h-100 {
    height: 100%;
}
.p-0{
    padding: 0 !important;
}
.pr-0{
    padding-right: 0 !important;
}
.pl-0{
    padding-left: 0 !important;
}

.container {
    width: 1340px;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;

    &.lg {
        width: 1232px;
    }

    &.md {
        width: 908px;
    }
}

.container-full {
    width: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}
.Toastify__toast-container {
    text-transform: capitalize;
}

.tooltip-information-wrap{
    cursor: pointer;
  }

.react-tooltip{
    width: 500px !important;
    z-index: 9999;
  }
 /* width */
//  ::-webkit-scrollbar {
//     width: 6px;
//   }
  
  /* Track */
//   ::-webkit-scrollbar-track {
//     background: white;
//   }
  
  /* Handle */
//   ::-webkit-scrollbar-thumb {
//     border-radius: 5px;
//     background: #888;
//   }
  
  /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
//     background: rgb(58, 57, 57);
//   }  
  
@include responsive(lg) {
    .container {
        width: 960px;
    }
    .inner-box{
        margin: 95px 25px 25px 70px;
        width: calc(100% - 95px);
    }
    .section-inner{
        padding: 25px 13px;
    }
}

@include responsive(md) {
    .container {
        width: 720px;
    }
    .inner-box{
        margin: 70px 0 25px 0 !important;
        padding: 0 15px;
        width: 100%;
    }
}

@include responsive(sm) {
    .container {
        width: 540px;
    }
    // .section-inner{
    //     padding: 25px 5px;
    // }
    .text-danger {
       font-size: 12px;
    }
}